import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

export const ActionsContainer = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

ActionsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ActionButton = ({
  comp = null,
  href = '',
  target = '',
  icon,
  label,
  func,
  tip,
  cond,
}) =>
  cond &&
  (comp ? (
    <Tooltip title={tip}>
      <GridActionsCellItem
        component={comp}
        href={href}
        target={target}
        icon={icon}
        label={label}
        color="inherit"
      />
    </Tooltip>
  ) : (
    <Tooltip title={tip}>
      <GridActionsCellItem icon={icon} label={label} onClick={func} color="inherit" />
    </Tooltip>
  ));

ActionButton.propTypes = {
  comp: PropTypes.object,
  href: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  func: PropTypes.func.isRequired,
  tip: PropTypes.string.isRequired,
  cond: PropTypes.bool.isRequired,
};
